import { Container } from './styles/termsPageStyles';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { UnsuscribeAlerts } from '../components/UnsuscribeAlerts';

import ReactGA from "react-ga4";

export const UnsuscribeAlertsPage = () => {

  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Unsuscribe Alerts Page" });

  return (
    <Container>
      <HeaderLayout>
        <UnsuscribeAlerts />
      </HeaderLayout>
    </Container>
  )
}