import { styled } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "50px",
  gap: "0px",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    gap: "50px",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
    gap: "0px",
  },
}))

export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "149.4%",
  color: "#000000",
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
}))
