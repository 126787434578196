import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"
import FondoMobile from "../../assets/images/termsAndCondition/FondoMobile.svg"
import FondoDesktop from "../../assets/images/termsAndCondition/FondoDesktop.svg"

interface Props {
  isEmpty: boolean
  loading: boolean
}

export const Container = styled(Box)<Props>(({ theme, isEmpty, loading }) => ({
  width: "100vw",
  minHeight: "100vh",
  backgroundImage: `url(${FondoMobile})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  backgroundSize: "cover",
  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${FondoDesktop})`,
  },
}))
