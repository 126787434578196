import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
  },
}))

export const Text = styled(Typography)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "149.4%",
    color: "#000000",
    marginBottom: "20px",
  },
}))
