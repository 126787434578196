import { useState, useEffect } from "react"
import { useAppDispatch } from "../../redux/hooks"
import { deleteAlertSuscription } from "../../redux/profile/profileSlice"
import { Container, Texto, SuccessContainer, SuccessImg } from "./style"
import { Spinner } from "../Spinner"
import frutaNena from "../../assets/images/ImageRegister.svg"
import theme from "../../theme/index"

export const UnsuscribeAlerts = () => {
  const dispatch = useAppDispatch()
  const [isUnsubscribed, setIsUnsubscribed] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleUnsubscribe = async () => {
      const searchParams = new URLSearchParams(document.location.search)
      const email = searchParams.get("email")

      if (!email || email === "undefined") {
        console.error("No se encontró un email válido en la URL")
        return
      }

      try {
        setLoading(true)
        await dispatch(deleteAlertSuscription(email)).unwrap()
        setIsUnsubscribed(true)
      } catch (err) {
        console.error("Hubo un error al procesar su solicitud")
      } finally {
        setLoading(false)
      }
    }

    handleUnsubscribe()
  }, [dispatch])

  return (
    <Container>
      {loading ? (
        <Spinner
          color={theme.palette.primary.main}
          width="50px"
          height="50px"
        />
      ) : isUnsubscribed ? (
        <SuccessContainer>
          <SuccessImg src={frutaNena} alt="Success Icon" />
          <Texto>
            Ha sido desuscripto, ya no recibirá más alertas. ¡Muchas gracias!
          </Texto>
        </SuccessContainer>
      ) : (
        <Texto>
          Hubo algunos problemas, el url de suscripción de alertas no está
          funcionando bien, por favor envíe un correo de contacto.
        </Texto>
      )}
    </Container>
  )
}
