import { MyTours } from '../components/MyTours';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { Container } from './styles/myTourPageStyles'; 
import { useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';

import ReactGA from "react-ga4";

export const MyTourPages = () => {

  const { doneBookingsShow, loading } = useAppSelector((state: RootState) => state.profile);

  const isEmpty = doneBookingsShow.length < 3;
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "MyTours Page" });
  return (
    <Container isEmpty={isEmpty} loading={loading}>
      <HeaderLayout>
        <MyTours />
      </HeaderLayout>
    </Container>
  )
}