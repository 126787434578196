import { styled } from "@mui/material/styles"
import { Box, Button, Typography } from "@mui/material"

type Props = {
  isMobile: boolean
}

export const Container = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "auto",
  width: "90%",
  [theme.breakpoints.up("md")]: {
    width: "60%",
  },
  paddingBottom: theme.spacing(3),
}))

export const TitleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  alignItems: "center",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}))

export const Title = styled(Typography)<Props>(({ isMobile, theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: isMobile
    ? theme.typography.pxToRem(24)
    : theme.typography.h4.fontSize,
  color: theme.palette.text.primary,
}))

export const QuantityContainer = styled(Box)<Props>(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}))

export const NumberToursText = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.text.primary,
}))

export const ToursText = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.text.primary,
}))

export const ButtonBooking = styled(Button)<Props>(({ isMobile, theme }) => ({
  display: isMobile ? "none" : "block",
  padding: theme.spacing(1, 2),
  gap: theme.spacing(2),
  background: theme.palette.primary.main,
  borderRadius: "50px",
  color: theme.palette.getContrastText(theme.palette.primary.main),
}))

export const MonthContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(2),
  margin: "0px auto",
  width: "100%",
}))

export const MonthsCardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  flexWrap: "wrap",
}))

export const FloatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
  marginBottom: theme.spacing(2),
  zIndex: theme.zIndex.appBar,
  marginTop: 0,
}))

export const Btn = styled(Button)(({ theme }) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: "19px",
  textAlign: "center",
  color: theme.palette.getContrastText(theme.palette.primary.main),
  borderRadius: "50px",
  padding: theme.spacing(2, 4),
}))

export const EmptyBookings = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.text.primary,
  textAlign: "center",
  opacity: 0.8,
  margin: "auto",
  marginInline: theme.spacing(4),
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.pxToRem(24),
  },
}))
