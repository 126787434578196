import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Container = styled(Box)(({ theme }) => ({
  width: "100vw",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: "0px",
  },
}))

export const Texto = styled(Typography)(({ theme }) => ({
  fontFamily: "Telolet",
  fontWeight: "400",
  fontSize: "45px",
  textAlign: "center",
  color: "#3366CC",
  lineHeight: "60px",
  padding: theme.spacing(2),
  maxWidth: "400px",
  [theme.breakpoints.up("md")]: {
    lineHeight: "80px",
    fontSize: "65px",
    maxWidth: "600px",
  },
}))

export const SuccessContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}))

export const SuccessImg = styled("img")(({ theme }) => ({
  flex: 1,
  maxWidth: "350px",
  aspectRatio: "1/1",
  minWidth: "200px",
  padding: theme.spacing(1),
}))
